

















































































import {Component, Mixins, Watch} from "vue-property-decorator"
import User from "@/models/User"
import UserService from "@/services/UserService"
import {getModule} from "vuex-module-decorators"
import Options from "@/models/vue/Options";
import LangModule from "@/store/LangModule";
import CommercialDialog from "@/components/dialog/CommercialDialog.vue"
import {MultipleItem} from "@/handlers/interfaces/ContentUI"
import PaginationMixin from "@/mixins/PaginationMixin"
import Handler from "@/handlers/Handler"
import CommercialService from "@/services/user/CommercialService"

@Component({components: {CommercialDialog}})
export default class CommercialsView extends Mixins(PaginationMixin) {
    lang: any = getModule(LangModule).lang
    dialog: boolean = false
    active: boolean = false
    all: boolean = false
    page: number = 1
    pageCount: number = 0
    loading: boolean = false
    itemsPerPage: number = 10
    totalItems: number = 0
    options: Options = new Options()
    search: string = ""
    headers = [
        {align: "center", divider: true, text: "Id", value: "id", width: "auto"},
        {align: "center", divider: true, text: this.lang.email, value: "email", width: "auto"},
        {align: "center", divider: true, text: this.lang.name, value: "name", width: "auto"},
        {align: "center", divider: true, text: this.lang.commercialName, value: "commercialName", width: "auto"},
        {align: "center", divider: true, text: this.lang.enabled, value: "active", width: "auto"},
        {align: "center", divider: true, text: this.lang.createdBy, value: "createdBy", width: "auto"},
    ]

    commercials: MultipleItem<User> = { items: [], totalItems: 0 }

    created() {
        if (this.$route.query.all === "1") { this.all = true }
        this.refresh()
    }

    async refresh() {
        await Handler.getItems(this, this.commercials, () =>
            CommercialService.getMyCommercials(this.page - 1, this.size, this.search)
        )
        this.setPageCount(this.commercials.totalItems!)
    }

    rowClick(user: User) { this.$router.push({path: "/commercials/" + user.id}) }
    openCreateDialog() { this.dialog = true }

    getMyCommercialsCSV() {
        UserService.getMyCommercialsCSV(this, this.page - 1, this.itemsPerPage, this.all, !this.active)
    }

    onSearchInputChanged() {
        this.page = 1
        this.refresh()
    }

    @Watch("page")
    onPageChanged() {
        this.refresh()
    }

}
