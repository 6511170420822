

















































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Address, { AddressType, AddressSelect } from "@/models/Address";
import AddressService from "@/services/AddressService";
import { getModule } from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Client from "@/models/Client";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import UserService from "@/services/UserService";
import CommercialService from "@/services/user/CommercialService";

@Component
export default class CommercialDialog extends Vue {

	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	@Prop() readonly client!: Client
	@Prop() addressId!: number

	name: string = ""
	lastname: string =""
	email: string = ""
	password: string = ""
	passwordMatch: string = ""
	commercialName: string = ""

	dialog: boolean = false;
	detailDialog: boolean = false
	loading: boolean = false
	address: Address = new Address()
	addresses: Address[] = []
	headers = [
		{ text: "Id", value: "id", width: "auto", align: "center" },
		{ text: this.lang.address, value: "address", width: "auto", align: "center" },
		{ text: this.lang.actions, value: "actions", width: "auto", align: "center" },
	];
	minLength:number = 4
	maxLength:number = 250

	rules = {
		required: (input: string) => (input ? true : this.lang.requiredField),
		email: (input: string) => StringTool.validateEmail(input) ? true : this.lang.emailNoValid,
		postalCode: (input: string) => StringTool.validatePostalCode(input) ? true : this.lang.invalidPostalCode,
		passwordRules: (v: string) => v && v.length >= 4 ? true : this.lang.password4CharactersRequired

	}

	createUser() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				 this.lang.warning, this.lang.userCreateQuestion,
				 async () => {
					 await CommercialService.createCommercial(this.email, this.password, this.name, this.commercialName)
					 this.$emit("refresh")
					 this.close()
				 }
			))
		}
	}

	close() {
		this.$emit("close")
	}
}
